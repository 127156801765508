@import 'src/styles/library';

.CustomerDashboard {

    & .teacher {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        border-bottom: 1px solid lightgray;
        font-weight: 500;

        & .register {
            font-weight: 400;
        }
    }

    & .class-room-hand {
        display: inline-grid;
        grid-template-columns: repeat(3, auto);
        align-items: center;
        column-gap: 30px;
        row-gap: 15px;
        padding: 30px;
    }

    & .ClassRoomHandComponent {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        padding: 15px;
        border-bottom: 1px solid lightgray;
    }

    & .class-room-hands {
        margin-bottom: 50px;

        &-title {
            margin-bottom: 25px;
        }
    }
}
