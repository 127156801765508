@import 'src/styles/library';

.ResetPassword {
    & h4 {
        margin: {
            bottom: 4 * $basePadding;
        }
        text-align: center;
        font: {
            weight: 700;
        }
    }

    & .success {
        text-align: center;

        & div {
            margin: {
                bottom: 4 * $basePadding;
            }
        }
    }
}
