@import 'src/styles/library';

.Dashboard {
    position: relative;
    min: {
        width: 100%;
        height: 100%;
    }

    & .actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin: {
            bottom: 4 * $basePadding;
        }

        & > * {
            margin: {
                left: $basePadding;
            }
        }
    }

    & .Loading {
        @include centerVH;

        position: absolute;
    }

    & a {
        &:hover {
            & button {
                text-decoration: underline;
                text-decoration-color: #c51162;
            }
        }
    }
}
