@import 'src/styles/library';

.ClubTeacherDashboard {
    margin: {
        top: -4 * $basePadding;
    }

    & .goBack {
        margin: {
            top: -60px;
        }
    }

    & h5 {
        margin: 4 * $basePadding 0;
        font: {
            weight: 700;
        }
    }
}
