@import 'src/styles/library';

.Credits {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin: {
        left: $basePadding/2;
        right: $basePadding/2;
    }

    & .credits {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;

        &:after {
            @include icon($icon-credits);

            margin: {
                left: 0.2em;
            }
            font-size: 1.2em;
        }
    }
}
