@import 'src/styles/library';

.Login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > * {
            margin: {
                bottom: var(--base-padding-3);
            }
        }
    }

    & .error {
        margin: {
            bottom: 3 * $basePadding;
        }
        font-size: px(14);
        color: red;
    }

    & .toRegister {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & button {
            margin: {
                top: $basePadding;
            }
        }
    }

    & .forgotPassword {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: {
            top: 4 * $basePadding;
        }

        & button {
            margin: {
                top: $basePadding;
            }
        }
    }
}
