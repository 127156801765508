@import 'src/styles/library';

.Header {
    & span {
        display: inline-block;
    }

    & .credits {
        font-size: px(20);
    }
}
