@import 'src/styles/library';

.RequestTeachersAccount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        margin: {
            bottom: 4 * $basePadding;
        }
        font: {
            size: 1.5rem;
            weight: 700;
        }

        & + div {
            width: 100%;
            max-width: 600px;
            margin: {
                bottom: 4 * $basePadding;
            }
        }
    }

    & .error {
        margin: {
            top: 4 * $basePadding;
        }
        color: $colorRed;
    }

    & .success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & button {
            margin: {
                top: 4 * $basePadding;
            }
        }
    }
}
