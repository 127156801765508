@import 'src/styles/library';

.DateTime {
    & .time {
        margin: {
            left: 0.5em;
        }
        font-size: 0.8em;
    }
}
