@import 'src/styles/library';

.Register {
    text-align: center;

    & h5 {
        font: {
            size: 24px;
            weight: 700;
        }
    }

    & .success {
        margin: {
            bottom: 4 * $basePadding;
        }

        & > * {
            margin: {
                bottom: 2 * $basePadding;
            }
        }
    }

    & .toLogin {
        margin: {
            top: 4 * $basePadding;
        }

        & > * {
            margin: {
                bottom: 2 * $basePadding;
            }
        }
    }
}
