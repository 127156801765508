@import 'src/styles/library';

.UserUpdate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //min-width: 100%;
    //min-height: 100%;

    & h4 {
        margin: {
            bottom: 2 * $basePadding;
        }
        padding: $basePadding;
        background: #555555;
        color: white;
        font: {
            weight: 700;
        }
    }
}
