@import 'src/styles/library';

.Customer {
    & .openCustomerMenu {
        cursor: pointer;
    }
}

.customerMenu {
    margin: {
        top: px(30);
    }
}
