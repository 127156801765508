@import 'src/styles/library';

.RequestTeachersAccountTeaser {
    margin: {
        top: 4 * $basePadding;
    }

    text-align: center;

    & > * {
        margin: {
            bottom: 2 * $basePadding;
        }
    }
}
