@import 'src/styles/library';

.forgotPassword {
    & .intro,
    & .success {
        width: 400px;
        max-width: 100%;
        margin: 0 auto 4 * $basePadding;
        line-height: 1.5;
        text-align: justify;
    }

    & .Loading {
        margin: auto;

        & ~ * {
            display: none;
        }
    }

    & .toLogin {
        display: flex;
        justify-content: center;
        align-items: center;

        & button {
            margin: 4 * $basePadding auto 0;
        }
    }
}
