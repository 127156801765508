@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function px($px) {
    $baseFontSize: 16;
    @return strip-unit($px) / $baseFontSize * 1rem;
}

@import 'variables/colors';
@import 'variables/fonts';
@import 'variables/mixed';
