@mixin icon($icon: null) {
    @if ($icon != null) {
        content: $icon;
    }
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin label {
    @include transition(all $base-transition-time ease-in-out);

    pointer-events: none;
    color: $color-grey-light;
    text-align: left;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 20px;
}

@mixin tray {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 vw(2 * $basePadding);
    border: {
        radius: vw($trayHeight/2) vw($trayHeight/2) 0 0;
        width: 1px;
        style: solid;
        color: black;
    }
    background: {
        color: var(--app-background);
    }
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    color: white;
    font: {
        size: vw(19px);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        border-radius: inherit;
    }
}
