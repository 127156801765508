@import 'src/styles/library';

@import './styles/reset';
@import './styles/fonts';
@import './styles/base';

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
}
