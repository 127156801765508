$colorCardBackground: white;
$colorHearts: red;
$colorClubs: black;
$colorSpades: black;
$colorDiamonds: red;

$colorFont: black;
$colorFontInverted: white;

$colorGrey: grey;
$colorSuccess: #126e2c;

$colorBlue: #1a51a4;
$colorRed: #c1394c;
