@import 'src/styles/library';

.Settings {
    padding: $basePadding;

    & .open {
        display: block;
        width: 100%;
    }
}
