@import 'src/styles/library';

.UserData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        width: 400px;
        text-align: center;

        & > div {
            flex: 0 0 100%;
            margin: {
                bottom: 2 * $basePadding;
            }
        }

        & div {
            width: 100%;
        }

        & button {
            margin: {
                top: 2 * $basePadding;
                left: auto;
                right: auto;
            }
        }
    }

    &.register,
    &.updateUser {
        & form {
            width: 800px;

            & > div {
                flex: 0 0 49%;
            }
        }
    }

    & .Mui-error {
        white-space: pre-wrap;
    }

    & .apiError {
        $color: #c51162;

        width: 300px;
        margin: {
            bottom: 2 * $basePadding;
        }
        padding: $basePadding;
        border: 1px solid $color;
        color: $color;
        line-height: 1.4;
    }
}
