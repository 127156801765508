@import 'src/styles/library';

.Drawer {
    & .MuiPaper-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
}
