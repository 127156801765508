@import 'src/styles/library';

.OrderCredits {
    & .orderWithStripe {
        position: relative;

        &.loading {
            & button {
                visibility: hidden;
                pointer-events: none;
            }
        }
    }
}

.orderStripeCredits {
    background: {
        size: 0 0;
    }

    &:before {
        $size: 50px;

        content: '';
        width: $size;
        height: $size;
        margin: {
            right: $basePadding;
        }
        background: {
            image: inherit;
        }
    }
}
