@import 'src/styles/library';
@import '../assets/icomoon/style.scss';
//@import 'zoom_overwrite';

:root {
    // css variables
    --base-font-size: #{$baseFontSize};
    --header-height: #{$baseHeaderHeight};
    --base-padding: #{$basePadding};
    --base-padding-2: #{2 * $basePadding};
    --base-padding-3: #{3 * $basePadding};
    --base-padding-4: #{4 * $basePadding};
    --base-padding-5: #{5 * $basePadding};
    //--dynamic-ratio: #{100vw / $dynamicBase};
    //--vh: 100vh;
}

html,
body {
    height: 100%;
    width: 100%;
    min: {
        width: auto !important;
        height: auto !important;
    }
    color: $colorFont !important;
}

html {
    font-size: var(--base-font-size);
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    padding: {
        top: calc(var(--base-padding-2) + var(--header-height));
        left: var(--base-padding-2);
        right: var(--base-padding-2);
    }
}
