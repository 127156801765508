@mixin centerV {
    @include transform(translateY(-50%));
    top: 50%;
}

@mixin centerH {
    @include transform(translateX(-50%));
    left: 50%;
}

@mixin centerVH {
    @include transform(translateX(-50%) translateY(-50%));
    top: 50%;
    left: 50%;
}

@mixin centerHV {
    @include centerVH;
}
