@import 'src/styles/library';

.Table {
    padding: {
        bottom: 4 * $basePadding;
    }

    & th {
        vertical-align: top;
    }

    & .MuiTablePagination {
        &-spacer {
            & + *,
            & + * + * {
                display: none;
            }
        }
    }

    & .noDataMessage {
        padding: 0 2 * $basePadding 2 * $basePadding;
        font: {
            style: italic;
        }
    }
}
