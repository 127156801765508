$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: 'fonts' !default;

$icon-chevron-left: '\f053';
$icon-chevron-right: '\f054';
$icon-chevron-up: '\f077';
$icon-chevron-down: '\f078';
$icon-microphone: '\f130';
$icon-microphone-slash: '\f131';
$icon-credits: '\e903';
$icon-flip: '\e901';
$icon-spades: '\e917';
$icon-clubs: '\e918';
$icon-diamonds: '\e919';
$icon-eye: '\e9ce';
$icon-heart: '\e9da';
$icon-cross: '\ea0f';
$icon-checkmark: '\ea10';
$icon-enter: '\ea13';
$icon-volume-medium: '\ea27';
$icon-volume-mute2: '\ea2a';
$icon-loop2: '\ea2e';
$icon-checkbox-checked: '\ea52';
$icon-checkbox-unchecked: '\ea53';
$icon-radio-checked2: '\ea55';
$icon-radio-unchecked: '\ea56';
$icon-settings: '\e900';
$icon-paperplane: '\e902';
